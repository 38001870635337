export const environment = {
    production: false,
    officeHourType: 'test',
    msalConfig: {
        auth: {
            clientId: '8c0ea287-be7f-4d67-bbc9-400ec22da7c7',
            authority: 'https://login.microsoftonline.com/cddc1229-ac2a-4b97-b78a-0e5cacb5865c',
        },
    },
    apiConfig: {
        server: {
            APIEndpoint: 'https://api.dev.rpd.mto.gov.on.ca',
            APIScopes: 'api://8c0ea287-be7f-4d67-bbc9-400ec22da7c7/access.dima.rpd',
        },
        graph: {
            scopes: 'user.read',
            uri: 'https://graph.microsoft.com/v1.0/me',
        }
    },
};